//  Foundation for Sites Settings
//  -----------------------------

// 1. Global
// ---------

$global-width: 62.5rem;
$small-font-size: 1.3125rem;
$foundation-palette: (
  primary: #1D7B72,
  secondary: #1D7B72,
  success: #3adb76,
  warning: #ffae00,
  alert: #ec5840,
)!default;

$light-gray: white;
$button-background: #2EC4B6;
$global-padding: 1.5rem;
$global-weight-normal: normal;
th {
    font-weight: normal;
    font-size:1em;
    text-align: left;
}
$table-is-striped: false;
$table-striped-background: white;
.mine{
  font-family: Georgia;
}

// 22. Menu
// --------
$menu-margin-nested: 0rem;
$dropdownmenu-arrow-color: #2EC4B6;
$dropdownmenu-background:#2EC4B6;


// NOTE: intro section
.intro{
  margin: 1rem;
}
.small-12 columns{
  border-bottom: 1px solid #999;
}

// Base Typography
// ------------------
$paragraph-margin-bottom: 1.5rem;
$header-sizes: (
  small: (
    'h1': 24,
    'h2': 20,
    'h3': 19,
    'h4': 18,
    'h5': 17,
    'h6': 16,
  ),
  medium: (
    'h1': 40,
    'h2': 31,
    'h3': 25,
    'h4': 20,
    'h5': 16,
    'h6': 14,
  ),
);

.peque {
    margin-left: 6rem;
}

// Newsletter
// ------------------

.section-index-wide-carte {
    max-width: 57.75rem;
    margin-top: 3rem;
}
.section-carte-panel {
    padding: 1rem;
    color: #444;
    margin-bottom: 1rem;
}
.section-carte-newsletter {
    margin-bottom: 1.5rem;
    padding: 1.5rem;
}
.section-carte-index-panel, .section-carte-panel {
    border-style: solid;
    border-width: 1px 0 1px;
    border-color: #ededed;
    background: #FFF;
}

  .section-carte-newsletter-description {
      margin-bottom: 8px;
      line-height: 1.5rem;
  }

.button-newsletter {
  background-color: #fce3e6;
  border-color: #ED5668;
  line-height: 1;
  border: 1px solid;
  padding: 0.85em 1em;
  margin: 0;
  width: 100%;
  font-size: 0.9rem;
  color: #ED5668;
  border-radius: 20%;
}
.input-newsletter {
  margin: 0 !important;
  font-size: 0.9rem !important;
  line-height: 1;
  color: #999;
  border-radius: 20%;
}

.news {
    margin-top: 0.6rem;
}
.section-carte-newsletter input {
  margin-bottom: 0;
}

// Button Ebay
// ------------------
.button-ebay {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.85em 1em;
    margin: 0 0 1rem 0;
    font-size: 0.9rem;
    background-color: #F07281;
    color: #fff;
}

// P progress-bar
// ---------
.progress-bar {
    margin-bottom: 0;
}
