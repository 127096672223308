// --------------------------------------------------
// Layout index page
// --------------------------------------------------

main {
  @include grid-row;

  //other elements
  //@include grid-column;
}
